.MessageMediaUnloadedSkeleton {
  width: 100%;
  height: 100%;
  position: relative;
}

.UnloadedMediaIconOverlay {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {

}
