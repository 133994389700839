.SectionContainer {
  display: flex;
  flex-direction: column;
}

.SectionContainerItem {
  margin-top: var(--global-margin);
}

.SectionContainerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SectionContainerHeaderRight {
  margin-left: auto;
}

@media only screen and (max-width: 600px) {
  .SectiontContainer {

  }
}
