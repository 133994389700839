.bar {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
}

.bar_time {
  color: white;
  font-size: 16px;
}

.bar_progress {
  flex: 1;
  border-radius: 5px;
  margin: 0 20px;
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bar_progress_knob {
  position: relative;
  height: 16px;
  width: 16px;
  border: 1.5px solid transparent;
  border-radius: 50%;
  background-color: rgb(196, 196, 196);
}
