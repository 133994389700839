.MessageMediaImageContainer {
  border-radius: var(--global-border-radius);
  background: inherit;

  width: 100%;
  height: 100%;
}

.MessageMediaImage {
  border-radius: var(--global-border-radius);

  width: 100%;
  height: 100%;
  opacity: 1;
  object-fit: contain;

  transition: opacity 200ms ease-in-out;
}

.IsLoading {
  opacity: 0;
}

@media only screen and (max-width: 600px) {

}
