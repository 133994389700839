.GenericHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 2rem;

  padding: var(--global-padding);
}

.GenericHeaderLeft {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: var(--global-margin);
}

.GenericHeaderRight {
  margin-left: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .GenericHeaderContainer {

  }
}
