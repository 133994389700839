.DebugConsoleContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  z-index: 1;
}

.DebugConsole {
  display: flex;
  flex-direction: column;

  padding: var(--global-padding);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  width: 100%;
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
}

.DebugConsoleHeader {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: var(--global-padding);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .DebugConsoleContainer {

  }
}
