.MessageMediaAudioContainer {
  width: 100%;
  height: 100%;
  min-height: 4rem;

  border-radius: var(--global-border-radius);
  background: inherit;
}

.MessageMediaAudioInnerContainer {
  width: 100%;
  height: 100%;
}

.MessageMediaAudio {
  border-radius: var(--global-border-radius);
  object-fit: contain;
}

@media only screen and (max-width: 600px) {

}
