@import "Pages/Pages.css";

.FriendViewContainer {
  gap: var(--global-margin);
}

.FriendButtonContainer {
  display: flex;
  flex-direction: row;
}

.FriendButtonSelectorContainer {
  display: flex;
  align-items: center;
}

.FriendButtonContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 600px) {
  .FriendViewContainer {

  }
}
