@import "Components/Components.css";
@import "Views/Views.css";

:root {
  --global-border-radius: 10px;
  --global-border-thickness: 1px;
  --global-padding: 10px;
  --global-margin: 10px;

  --scrollbar-border-radius: 30px;
  --scrollbar-width: 10px;

  --card-padding: 25px;

  --dialog-padding-left: 35px;
  --dialog-padding-right: 35px;
  --dialog-padding-top: 20px;
  --dialog-padding-bottom: 20px;
  --dialog-padding-left-constrained: 15px;
  --dialog-padding-right-constrained: 15px;
  --dialog-padding-top-constrained: 10px;
  --dialog-padding-bottom-constrained: 0px;
  --dialog-margin: 20px;
  --dialog-margin-constrained: 10px;
  --dialog-backdrop-opacity: 0.6;

  --generic-box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
}

html, body, #root, .App {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(125, 125, 125);
  border-radius: var(--scrollbar-border-radius);

  transition: background-color 200ms linear;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(150, 150, 150);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(200, 200, 200);
}

.BrandingImage {
  width: 100%;
  height: 100%;
}

.BrandingTitle {
  margin-top: var(--global-margin);
}

.NoPadding {
  padding: 0;
}

.FullWidth {
  width: 100%;
}

.GenericContextMenu {

}

.GenericContextMenuPaper {

}

.GenericPopover {
  text-align: justify;
}

.ToastHolder {
  position: absolute;
  bottom: 0;
  right: 0;

  margin: var(--global-margin);
}
