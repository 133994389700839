.FlagSwitchContainer {
  width: 100%;
}

.FlagSwitchInput {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .FlagSwitchContainer {

  }
}
