.MessageFileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 5px;
  border-radius: var(--global-border-radius);

  height: 100%;
}

.MessageFileContainerLeft {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.MessageFileContainerIcon {
  align-self: center;
}

.MessageFileContainerRight {
  display: flex;
  flex-direction: row;
  margin-left: 10px;

  width: 100%;
}

.MessageFileContainerTextSection {
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
}

.MessageFileContainerDownloadButton {
  margin-left: auto !important;
  width: 50px;
  height: 50px;
}

@media only screen and (max-width: 600px) {

}
