@import "Pages/Pages.css";

.ErrorViewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ErrorViewQuicklinksContainer {
  display: flex;
  flex-direction: row;
  margin-top: var(--global-margin);
}

.BackButton {

}

.HomeButton {
  margin-left: var(--global-margin) !important;
}
