@import "Subcomponents/MessageAudio/MessageAudio.css";
@import "Subcomponents/MessageFile/MessageFile.css";
@import "Subcomponents/MessageImage/MessageImage.css";
@import "Subcomponents/MessageVideo/MessageVideo.css";

.MessageMediaContainer {
  border-radius: var(--global-border-radius);
  margin-top: var(--global-margin);

  height: inherit;
  width: 24rem;
}

.MessageMediaContainer:first-child {
  margin-top: 0;
}

@media only screen and (max-width: 600px) {
  .MessageMediaContainer {
    width: auto;
  }
}
