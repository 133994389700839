.GenericDialogContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  visibility: hidden;
  z-index: 10;

  transition: visibility 200ms linear;
}

.GenericDialogInnerContainer {
  display: flex;
  flex-direction: column;
  padding: var(--dialog-padding-top) var(--dialog-padding-right) var(--dialog-padding-bottom) var(--dialog-padding-left);
  gap: var(--dialog-margin);
  border-radius: var(--global-border-radius);

  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  min-width: 25rem;
  max-height: 50%;
  max-width: 50%;

  transition: opacity 200ms ease-in-out;
}

.GenericDialogTitle {
  overflow-wrap: break-word;
}

.GenericDialogContentScrollContainer {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.GenericDialogContentContainer {
  display: flex;
  flex-direction: column;

  overflow-y: hidden;
  overflow-x: hidden;

  padding: var(--dialog-padding);
  gap: var(--global-margin);
}

.GenericDialogButtonOuterContainer {
  width: 100%;
  margin-top: auto;
}

.GenericDialogButtonContainer {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.GenericDialogTextContainer {
  display: flex;
  flex-direction: column;
  gap: var(--global-margin);
}

.GenericDialogBackdrop {
  position: absolute;
  top: 0;
  left: 0;

  opacity: var(--dialog-backdrop-opacity);

  width: 100%;
  height: 100%;
  z-index: -1;

  transition: opacity 200ms ease-in-out;
}

@media only screen and (max-width: 600px) {
  .GenericDialogInnerContainer {
    position: static;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;

    align-items: center;

    border-radius: 0;
  }

  .GenericDialogContentContainer {
    padding: var(--dialog-padding-top-constrained) var(--dialog-padding-right-constrained) var(--dialog-padding-bottom-constrained) var(--dialog-padding-left-constrained);    ;
  }

  .GenericDialogButtonContainer {
    margin-top: auto;
    margin-bottom: var(--global-margin);
  }
}

.GenericDialogContainer-enter {
  visibility: hidden;
}

.GenericDialogContainer-enter-done {
  visibility: visible;
}

.GenericDialogContainer-exit {
}

.GenericDialogContainer-exit-done {
}

.GenericDialogInnerContainer-enter {
  opacity: 0;
}

.GenericDialogInnerContainer-enter-done {
  opacity: 1;
}

.GenericDialogInnerContainer-exit {
  opacity: 1;
}

.GenericDialogInnerContainer-exit-done {
  opacity: 0;
}

.GenericDialogBackdrop-enter {
  opacity: 0;
}

.GenericDialogBackdrop-enter-done {
  opacity: var(--dialog-backdrop-opacity);
}

.GenericDialogBackdrop-exit {
  opacity: var(--dialog-backdrop-opacity);
}

.GenericDialogBackdrop-exit-done {
  opacity: 0;
}
