.Separator {
  height: 2px;
  width: 100%;
  margin: var(--global-margin) 0;
}

@media only screen and (max-width: 600px) {
  .Separator {

  }
}
