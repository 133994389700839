.player {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 4px;
}

.player_controls {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
}

.player_display {
  flex-grow: 1;
  width: 100%;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.player_button {
  display: flex;
  margin-right: 2.5px;
  background-color: transparent;
  border: none;
  color: white;
  justify-content: center;
}

.player_volume_container {
  position: relative;
  display: flex;
  margin-right: 5px;
}

.player_volume_container:hover {
  color: orange;
}

.player_volume_slider {
  flex-direction: column;
  align-items: center;

  position: absolute;
  translate: 0 -100%;
  display: none;
  color: white;
  width: 100%;
  height: 100px;
}

.player_button:hover {
  color: orange;
}

.volume_bar_progress {
  border-radius: 5px;
  width: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.volume_bar_progress_knob {
  position: absolute;
  cursor: pointer;
  height: 16px;
  width: 16px;
  border: 1.5px solid transparent;
  border-radius: 50%;
  background-color: rgb(196, 196, 196);
}
