.ViewContainer {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  overflow-y: auto;
  overflow-x: hidden;

  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .ViewContainer {
    padding: 0;
  }

  .ViewContainerNonAdaptive {
    padding: var(--global-padding);
  }
}
