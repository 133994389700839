.TextComboContainer {
  display: flex;
  flex-direction: column;
}

.TextComboField {
  border: none;
  outline: none;

  text-indent: 5pt;

  width: 100%;
}

.TextComboTop {

}

.TextComboBottom {
  display: flex;
  flex-direction: row;

  box-sizing: border-box;
  margin-bottom: var(--global-margin);
  border: var(--global-border-thickness) solid;
  border-radius: var(--global-border-radius);

  height: 3.5rem;

  transition: border-color 50ms linear;
}

.TextComboBefore {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.TextComboAfter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.TextComboFieldStatusText {

}

@media only screen and (max-width: 600px) {
  .TextComboContainer {

  }

  .TextComboBottom {
    height: 3rem;
  }
}
