.LoginPageContainer {
  position: relative;
  z-index: 1;
}

.LoginForm {

}

.LoginFormItem {
  margin-top: var(--global-margin) !important;
}
