.MessageMediaVideoContainer {
  width: 100%;
  height: 100%;
  min-height: 4rem;

  border-radius: var(--global-border-radius);
  background: inherit;
}

.MessageMediaVideo {
  border-radius: var(--global-border-radius);

  width: 100%;
  height: 100%;
  object-fit: contain;
}

.MessageMediaVideoOverlay {

}

@media only screen and (max-width: 600px) {

}
